import cleanUrlComponent from './clean-url-component'

export default function seoUrlPathEncode(
  val: string | undefined | null,
  kind: 'location' | 'role' | 'title' | 'blog',
): string {
  if (!val) {
    // eslint-disable-next-line default-case
    switch (kind) {
      case 'location':
        return 'all-locations'
      case 'role':
        return 'full-time'

      case 'title':
        return 'job-title'

      case 'blog':
        return 'blog-post'
    }
  }

  return cleanUrlComponent(val)
}
