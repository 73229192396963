'use client'

import { useEffect } from 'react'

import { miscPageViewEvent } from '@lib/analytics-events'

type PageViewEventProps = {
  location: string
  customEvent?: () => void
}

export default function PageViewEvent({ location, customEvent }: PageViewEventProps): null {
  useEffect((): void => {
    miscPageViewEvent(location)
    customEvent?.()
  }, [location, customEvent])

  return null
}
