export default function cleanUrlComponent(val: string): string {
  const res = val
    .toLowerCase()
    .replace(
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
      '',
    )
    // For custom encoding use valid unreserved characters
    // https://developers.google.com/maps/url-encoding
    // + means slash. Is used just in locations and role URL segment
    .replace(/\//g, '+')
    .replace(/(\?|\(|\))/g, '')
    .replace(/( |_)/g, '-')
    .substring(0, 64)

  /**
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent
   *
   * The encodeURIComponent() function encodes a URI by replacing each instance of
   * certain characters by one, two, three, or four escape sequences representing
   * the UTF-8 encoding of the character (will only be four escape sequences for
   * characters composed of two "surrogate" characters).
   */
  return encodeURIComponent(res)
}
