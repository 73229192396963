import { createRef, useState } from 'react'

import { Location } from '@types'

import { AsyncTypeahead, Menu, MenuItem } from 'react-bootstrap-typeahead'
import Typeahead from 'react-bootstrap-typeahead/types/core/Typeahead'

import useLocale from '@hooks/client/useLocale'
import useHttpRequest from '@hooks/useHttpRequest'
import getDictionary from '@lib/helpers/cross-env/get-dictionary'

import styles from './typeahead.module.scss'

export type LocationTypeAheadProps = {
  currentLocation: Location
  setLocation: (location: Location) => void
  setShowError: (value: boolean) => void
  showError: boolean
  placeholder?: string
  maxResults?: number
  className?: string
}

export default function LocationTypeAhead({
  currentLocation,
  setLocation,
  setShowError,
  showError,
  placeholder,
  maxResults,
  className,
}: LocationTypeAheadProps): JSX.Element {
  const { get } = useHttpRequest()
  const locale = useLocale()
  const { zipCode } = getDictionary(locale)
  const actualPlaceholder = placeholder || `City or ${zipCode}`

  const [locations, setLocations] = useState<Location[]>([])
  const [isInputEmpty, setIsInputEmpty] = useState<boolean>(true)

  const locationRef = createRef<Typeahead>()

  const handleLocationSearch = async (query: string): Promise<void> => {
    const items: Location[] | undefined = await get<Location[]>(
      `locations/autocomplete?query=${encodeURIComponent(query)}`,
      { service: 'web' },
    )
    if (items) {
      setLocations(items)
    }
  }

  return (
    <div className={`${showError ? styles['typeahead-error'] : ''} ${styles.typeahead} ${className || ''}`}>
      <AsyncTypeahead
        id="location-search"
        isLoading={false}
        labelKey="displayName"
        minLength={3}
        maxResults={maxResults}
        paginate={false}
        onSearch={handleLocationSearch}
        inputProps={{ 'aria-label': 'Location search input' }}
        onChange={(selected): void => {
          if (selected && selected.length > 0) {
            const location = selected[0] as Location
            setShowError(false)
            setLocation({
              name: location.name,
              id: location.id,
              type: location.type,
              location: location.location?.map((p) => p.toString()),
              displayName: location.displayName,
              metropolis: location.metropolis,
              county: location.county,
              state: location.state,
              postcode: location.postcode,
              active: location.active,
            })
          }
        }}
        onInputChange={(): void => {
          const inputValue = locationRef?.current?.getInput()?.value
          setIsInputEmpty(!locationRef?.current?.getInput()?.value?.length)
          if (!inputValue) {
            setLocation({
              name: '',
              id: '',
              type: '',
              location: [],
              displayName: '',
              metropolis: '',
              county: '',
              state: '',
              postcode: '',
              active: true,
            })
          }
        }}
        filterBy={(): boolean => {
          return true
        }}
        onFocus={(): void => setShowError(false)}
        ref={locationRef}
        options={locations}
        data-testid="location-typeahead"
        defaultSelected={currentLocation ? [currentLocation] : []}
        emptyLabel={<div className={styles['item-no-match']}>No results found</div>}
        searchText={<div className={styles['item-searching']}>Searching</div>}
        promptText={<div className={styles['item-prompt']}></div>}
        placeholder={showError ? "This field can't be empty" : actualPlaceholder}
        renderMenu={(results, menuProps, state): JSX.Element => (
          <Menu className={styles['typeahead-item-menu']} {...menuProps} maxHeight="260px">
            {results.length > 0 && locationRef?.current?.getInput()?.value.replace(/ /g, '') !== '' && (
              <div className={styles['menu-title']}>Select a location</div>
            )}
            {results.map((result, index) => (
              <MenuItem option={result} position={index} key={index}>
                <div
                  className={
                    styles['typeahead-item'] +
                    (state.activeIndex === index ? ` ${styles['typeahead-item-active']}` : '')
                  }
                >
                  {(result as Location).displayName}
                </div>
              </MenuItem>
            ))}
          </Menu>
        )}
      />
      {showError && !isInputEmpty ? <div className={styles.error}>Select a location</div> : null}
    </div>
  )
}
