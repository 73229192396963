import { Dispatch, SetStateAction, createRef, useState } from 'react'

import { AddressAutocomplete, AddressPrediction, RestResponse } from '@types'

import { AsyncTypeahead, Menu, MenuItem, RenderMenuProps } from 'react-bootstrap-typeahead'
import Typeahead from 'react-bootstrap-typeahead/types/core/Typeahead'
import TextareaAutosize from 'react-textarea-autosize'

import useHttpRequest from '@hooks/useHttpRequest'

import styles from './typeahead.module.scss'

export type AddressDescription = {
  prediction: string
  refs: string
  complete: boolean
}

export type AddressTypeAheadProps = {
  currentAddress: AddressDescription
  setAddress: (location: AddressDescription) => void
  setShowError: Dispatch<SetStateAction<boolean>>
  showError: boolean
  placeholder?: string
  disabled?: boolean
  menuStyle: string
}

export default function AddressTypeAhead({
  currentAddress,
  setAddress,
  setShowError,
  showError,
  disabled,
  placeholder = 'Start typing',
  menuStyle,
}: AddressTypeAheadProps): JSX.Element {
  const { get } = useHttpRequest()

  const [addresses, setAddresses] = useState<AddressPrediction[]>([])

  const locationRef = createRef<Typeahead>()

  const handleLocationSearch = async (query: string): Promise<void> => {
    const response = await get<RestResponse<string>>(`address/autocomplete?query=${encodeURIComponent(query)}`)
    if (response?.data) {
      const parsedData: AddressAutocomplete | undefined = JSON.parse(response?.data)
      if (parsedData) {
        const items: AddressPrediction[] = parsedData.predictions
        if (items) {
          const sortedItems = items.sort((a: AddressPrediction, b: AddressPrediction): number => {
            const aMatch = a.prediction.toLowerCase().startsWith(query.toLowerCase())
            const bMatch = b.prediction.toLowerCase().startsWith(query.toLowerCase())
            if (aMatch && bMatch) {
              return 0
            }
            if (aMatch) {
              return -1
            }
            return 1
          })
          setAddresses(sortedItems)
        }
      }
    }
  }

  return (
    <div className={showError ? `${styles.typeahead} ${styles['typeahead-error']}` : styles.typeahead}>
      <AsyncTypeahead
        id="location-search"
        isLoading={false}
        disabled={disabled}
        labelKey="prediction"
        minLength={1}
        paginate={false}
        filterBy={(): boolean => {
          return true
        }}
        onSearch={handleLocationSearch}
        onChange={(selected): void => {
          const address = selected[0] as AddressPrediction
          if (selected && selected.length > 0) {
            setShowError(false)
            setAddress(address)
            ;(document?.activeElement as HTMLElement).blur()
          }
        }}
        ref={locationRef}
        options={addresses}
        defaultSelected={currentAddress.prediction ? [currentAddress] : []}
        emptyLabel={<div className={styles['typeahead-item']}>Keep typing to display more results</div>}
        searchText={<div className={styles['item-searching']}>Searching</div>}
        promptText={<div className={styles['item-prompt']}></div>}
        placeholder={showError ? "This field can't be empty" : placeholder}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        renderInput={(renderInputProps: any): JSX.Element => {
          const { inputRef, referenceElementRef, ...inputProps } = renderInputProps
          return (
            <>
              <TextareaAutosize
                {...inputProps}
                ref={(input): void => {
                  inputRef(input)
                  referenceElementRef(input)
                }}
                autoComplete="off"
              />
            </>
          )
        }}
        renderMenu={(results, menuProps, state): JSX.Element => {
          // remove undefined values from menuProps
          const keys = Object.keys(menuProps) as Array<keyof RenderMenuProps>
          keys.forEach((key) => {
            if (key in menuProps && menuProps[key] === undefined) {
              delete menuProps[key]
            }
          })
          return (
            <Menu className={`${menuStyle || ''} ${styles['typeahead-item-menu']}`} {...menuProps}>
              {results.map((result, index) => (
                <MenuItem option={result} position={index} key={index}>
                  <div
                    className={
                      styles['typeahead-item'] +
                      (state.activeIndex === index ? ` ${styles['typeahead-item-active']}` : '')
                    }
                  >
                    {(result as AddressPrediction).prediction}
                  </div>
                </MenuItem>
              ))}
            </Menu>
          )
        }}
      />
    </div>
  )
}
