import { Fragment, createRef, useState } from 'react'

import type { JobDescription, MicroRole } from '@types'

import { AsyncTypeahead, Menu, MenuItem } from 'react-bootstrap-typeahead'
import Typeahead from 'react-bootstrap-typeahead/types/core/Typeahead'

import useHttpRequest from '@hooks/useHttpRequest'

import styles from './typeahead.module.scss'

export type JobTypeAheadProps = {
  className?: string
  currentJob: JobDescription
  disabled?: boolean
  maxResults?: number
  placeholder?: string
  research?: boolean
  setJob: (job: JobDescription) => void
  setShowError: (value: boolean) => void
  showError: boolean
}

export default function JobTypeAhead({
  className,
  currentJob,
  disabled,
  maxResults = 11,
  placeholder = 'Job Title',
  research = false,
  setJob,
  setShowError,
  showError,
}: JobTypeAheadProps): JSX.Element {
  const { get } = useHttpRequest()

  const [jobs, setJobs] = useState<MicroRole[]>([])
  const jobRef = createRef<Typeahead>()

  const handleJobSearch = async (query: string): Promise<void> => {
    if (!research) {
      setJob({ micro: query, macro: '' })
    }
    const items: MicroRole[] | undefined = await get<MicroRole[]>(
      `jobs/autocomplete?query=${encodeURIComponent(query)}`,
      {
        service: 'web',
      },
    )
    if (items) {
      const sortedItems = items.sort((a: MicroRole, b: MicroRole): number => {
        const aMatch = a.microRoles.toLowerCase().startsWith(query.toLowerCase())
        const bMatch = b.microRoles.toLowerCase().startsWith(query.toLowerCase())
        if (aMatch && bMatch) {
          return 0
        }
        if (aMatch) {
          return -1
        }
        return 1
      })
      setJobs(sortedItems)
    }
  }

  return (
    <div className={`${showError ? styles['typeahead-error'] : ''} ${styles.typeahead} ${className || ''}`}>
      <AsyncTypeahead
        disabled={disabled}
        isLoading={false}
        labelKey="microRoles"
        minLength={1}
        maxResults={maxResults}
        id="job-typeahead"
        onSearch={handleJobSearch}
        onInputChange={(text): void => {
          if (!text && !research) {
            setJob({ micro: '', macro: '' })
          }
        }}
        filterBy={(): boolean => {
          return true
        }}
        inputProps={{ 'aria-label': 'Alternate Role search input' }}
        onChange={(selected): void => {
          if (selected && selected.length > 0) {
            const job = selected[0] as MicroRole
            setShowError(false)
            setJob({
              micro: job.microRoles,
              macro: job.macroRoles,
            })
            jobRef?.current?.getInput()?.blur()
          }
        }}
        onFocus={(): void => setShowError(false)}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ref={jobRef}
        options={jobs}
        defaultSelected={
          currentJob.micro !== '' ? [{ microRoles: currentJob.micro, macroRoles: currentJob.macro }] : []
        }
        data-testid="job-typeahead"
        placeholder={showError ? "This field can't be empty" : placeholder}
        emptyLabel={<div className={styles['item-no-match']}>No results found</div>}
        searchText={<div className={styles['item-searching']}>Searching</div>}
        promptText={<div className={styles['item-prompt']}></div>}
        renderMenu={(results, menuProps, state): JSX.Element => (
          <Menu className={styles['typeahead-item-menu']} {...menuProps} maxHeight="260px">
            {results.length > 0 && currentJob.micro.replace(/ /g, '') !== '' && (
              <div className={styles['menu-title']}>Select a role</div>
            )}
            {results.map((result, index) => {
              const label = (result as MicroRole).microRoles
                .toLowerCase()
                .split(state.text.toLowerCase())
                .map((x, i) => (
                  <Fragment key={i}>
                    {i > 0 ? state.text : ''}
                    <b>{x}</b>
                  </Fragment>
                ))
              return (
                <MenuItem option={result} position={index} key={index}>
                  <div
                    className={
                      styles['typeahead-item'] +
                      (state.activeIndex === index ? ` ${styles['typeahead-item-active']}` : '')
                    }
                  >
                    <span>{label}</span>
                  </div>
                </MenuItem>
              )
            })}
          </Menu>
        )}
      />
    </div>
  )
}
